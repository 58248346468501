<template>
	<v-card class="wr_grey_1" flat>
		<AppFeaturesTheme
			:features="features"
			@handle-function-call="handleFunctionCall"
		></AppFeaturesTheme>

		<!-- Collaborate document -->
		<CollaborateBoardDialog
			v-model="document_dialog"
			:dialog="document_dialog"
			@close="document_dialog = false"
			@confirm="redirectToEditor"
		></CollaborateBoardDialog>

		<!-- Collaborate research paper -->
		<CollaborateBoardDialog
			v-model="research_paper_dialog"
			:dialog="research_paper_dialog"
			@close="research_paper_dialog = false"
			@confirm="redirectToResearchPaper"
		></CollaborateBoardDialog>
	</v-card>
</template>

<script>
import AppFeaturesTheme from "@/components/ui/AppFeaturesTheme.vue";
import CollaborateBoardDialog from "@/components/shared/CollaborateBoardDialog.vue";

export default {
	name: "OnBoardEditor",

	data() {
		return {
			document_dialog: false,
			research_paper_dialog: false,
			features: [
				// {
				// 	img: "plus",
				// 	label: "app.new_document",
				// 	method_name: null,
				// },
				{
					img: "partners",
					label: "app.collaborate_document",
					method_name: "collaborateDocument",
				},
				{
					img: "contract",
					label: "app.collaborate_research_paper",
					method_name: "collaborateResearchPaper",
				},
			],
		};
	},

	components: {
		AppFeaturesTheme,
		CollaborateBoardDialog,
	},

	methods: {
		handleFunctionCall(method_name) {
			if (method_name) {
				this[method_name]();
			}
		},

		collaborateDocument() {
			this.document_dialog = true;
		},

		collaborateResearchPaper() {
			this.research_paper_dialog = true;
		},

		async redirectToEditor(id) {
			let exists = await this.$store.dispatch("etherpad/doesPadExists", {
				pad_id: id,
			});
			if (!exists) {
				console.error("not exists");
			} else {
				this.$router.push({
					name: "EditorExplorePage",
					params: {
						pad_id: id,
					},
					query: {
						collaborative_map: true,
					},
				});
			}
		},

		async redirectToResearchPaper(collaboration_id) {
			try {
				this.$router.push({
					name: "ToolsCollaborateResearchPaper",
					params: {
						id: collaboration_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
